$jjBlack: #212121;
$jjRed: #ca0018;
$jjBlue: #000099;
$primaryBlue: #007bff;
$jjGray: #d8d8d8;
$jjZebraGray: #f4f4f4;
$white: #fff;
$balck: #000;

// screen size
$screen-xl: 1400px;
$screen-lg: 992px;
$screen-md: 768px;
$screen-sm: 480px;

// custom bootstrap variables
$body-color: $jjBlack;
$danger: $jjRed;
$border-color: $jjGray;
