.from-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  .form-msg {
    font-size: 0.9em;
    line-height: normal;
  }

  .link-wrapper a {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    text-indent: -9999px;
    overflow: hidden;
    margin: 0;
    left: -27px;
    top: 4px;
    background: url(../../assests/question.svg) no-repeat center;
    background-size: 90% auto;
  }

  .lead {
    font-size: 1.5rem;
  }

  @include device-size('sm') {
    .quick-validation-form {
      padding-left: 2.5rem;
    }
  }
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;

  .polocy-label {
    font-weight: normal;
  }

  &.form-group-require {
    padding-right: 10px;

    &::after {
      content: '*';
      display: inline-block;
      color: $jjRed;
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
}

.form-control {
  @include border-radius(3px);
  padding: 12px;
  font-size: 1.6rem;
  line-height: 1.1em;
  height: calc(1.5em + 1.2rem + 2px);

  &:focus {
    box-shadow: none;
    border: 1px solid $jjBlack;
  }

  &.is-invalid {
    background-color: rgba($jjRed, 0.05);
  }

  &[type='checkbox'] {
    width: auto;
    display: inline-block;
    height: auto;
    margin-right: 1rem;
  }
}

// Redirect
.valid-success {
  text-align: center;

  &::before {
    display: inline-block;
    content: '';
    width: 60px;
    height: 60px;
    background: url(../../assests/check.svg) no-repeat center;
    background-size: 100% auto;
  }

  p {
    margin-top: 2rem;
    font-size: 1.4em;

    .red {
      color: $jjRed;
    }

    .count-down {
      font-size: 1.5em;
      font-style: italic;
    }
  }
}

.font14 {
  font-size: 1.4rem;
}

.mt10 {
  margin-top: 10px;
}

.break-all {
  word-break: break-all;
}