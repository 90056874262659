@import './variables';
@import './minxins';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// common
@import './common/layout';
@import './base';

// pages
@import './pages/form';
