.btn.btn-jj {
  border-radius: 0;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;

  &.btn-jj-red {
    @include jj-btn($white, $jjRed);
  }

  &.btn-jj-blue {
    @include jj-btn($white, $jjBlue);
  }
}

label {
  font-weight: bold;
}

.hidden {
  display: none;
}

.text-red {
  color: $jjRed;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.loading {
  .spinner-common {
    width: 3.2rem;
    height: 3.2rem;
    position: relative;
    left: 50%;
    top: 30%;
    margin-left: -1.6rem;
  }

  &.loading-fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba($jjBlack, 0.25);
  }
}

// form
input[type='text'],
input[type='password'],
select {
  @include device-size('x-sm') {
    @include placeholder(1.2rem);
  }
}
