@import './fonts';

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  font-family: $fontPrimary;
}

.page-main-wrapper {
  border-top: 1px solid $jjGray;
}

.page-main {
  min-height: calc(100vh - 290px - 100px - 44px);
  padding: 5rem 0;
  width: 100%;

  .page-main-inner {
    padding: 0 1.5rem;
  }

  @include device-size('lg') {
    min-height: calc(100vh - 175px - 100px - 44px);
  }

  @include device-size('x-sm') {
    padding: 2.5rem 0;
  }
}

h1 {
  font-size: 2.4rem;
  line-height: 1.41667;
  margin-bottom: 2rem;

  @include device-size('lg') {
    font-size: 4.8rem;
    line-height: 1.125;
  }
}

.btn {
  font-size: 1em;
}

@import './header';
@import './footer';
