//   Font Faces
//families
$font-primary: 'JJ Circular Std Book';
$font-black: 'JJ Circular Std Black';

// regular
// @include font-face($font-primary, '../fonts/roboto/Circular-Book');
// @include font-face($font-black, '../fonts/roboto/Circular-Black');

$fontPrimary: $font-primary, Arial, sans-serif;
$fontBlack: $font-black, 'Arial Black', Arial, sans-serif;
