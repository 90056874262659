@mixin jj-btn($textColor, $bgColor) {
  font-size: 1.6rem;
  color: $textColor;
  background-color: $bgColor;

  &:hover {
    background-color: $textColor;
    color: $bgColor;
    border: 1px solid $bgColor;
  }
}

@mixin device-size($size) {
  @if $size == 'x-sm' {
    @media screen and (max-width: ($screen-sm - 1px)) {
      @content;
    }
  } @else if $size == 'sm' {
    @media screen and (max-width: ($screen-md - 1px)) {
      @content;
    }
  } @else if $size == 'tablet' {
    @media screen and (min-width: $screen-md) {
      @content;
    }
  } @else if $size == 'tablet-only' {
    @media screen and (min-width: $screen-md) and (max-width: 1024px) {
      @content;
    }
  } @else if $size == 'lg' {
    @media screen and (min-width: $screen-lg) {
      @content;
    }
  } @else if $size == 'x-lg' {
    @media screen and (min-width: $screen-xl) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin font-face(
  $family,
  $file,
  $fontName,
  $weight: normal,
  $style: normal,
  $stretch: normal
) {
  @font-face {
    font-family: $family;
    font-stretch: $stretch;
    font-style: $style;
    font-weight: $weight;
    src: url('#{$file}/#{$fontName}.eot');
    src: url('#{$file}/#{$fontName}.eot#iefix') format('embedded-opentype'),
      url('#{$file}/#{$fontName}.woff2') format('woff2'),
      url('#{$file}/#{$fontName}.woff') format('woff'),
      url('#{$file}/#{$fontName}.ttf') format('truetype');
  }
}

@mixin placeholder($fontSize: 1em) {
  &::-webkit-input-placeholder {
    // color: $color;
    // opacity: $opacity;
    font-size: $fontSize;
  }

  &::-moz-placeholder {
    font-size: $fontSize;
  }

  &:-moz-placeholder {
    font-size: $fontSize;
  }

  &:-ms-input-placeholder {
    font-size: $fontSize;
  }
}
