.footer {
  width: 100%;
  font-size: 0.875rem;
  background-color: $balck;
  color: $white;
  padding: 20px 30px 30px;

  .main-footer {
    text-align: center;
    line-height: 26px;
  }

  .footer-menu {
    list-style: none;
    padding: 0;

    li {
      list-style: none;
      display: inline-block;
      padding: 0 1.2rem;
      vertical-align: middle;
    }

    a {
      color: $white;
      font-size: 1rem;
    }

    &.social-links {
      a {
        display: block;
        width: 26px;
        height: 26px;
        background: url(../../assests/twitter.svg) no-repeat center;
        background-size: 20px auto;
        overflow: hidden;
        text-indent: -9999px;

        &.linkedin {
          background-image: url(../../assests/linkedin.svg);
        }
      }
    }
  }

  .tips {
    padding-top: 20px;
    border-top: 1px solid #6d727c;
    margin-top: 20px;
  }

  @include device-size('lg') {
    padding-left: 60px;
    padding-right: 60px;

    .footer-logo img {
      max-width: 120px;
      height: auto;
    }

    .footer-menu {
      padding-left: 1.2rem;
      border-left: 1px solid #6d727c;
      margin-left: 1.2rem;

      &:first-of-type {
        margin-left: 2.4rem;
      }
    }
  }

  @media screen and (max-width: ($screen-lg - 1px)) {
    .fl,
    .fr {
      float: none;
    }

    .footer-logo {
      margin: 10px auto;
    }
  }
}
