.header {
  width: 100%;

  & > div {
    padding: 1rem 30px;
  }

  .pre-text {
    background-color: $jjBlue;
    color: $white;
    font-family: $fontBlack;
    font-weight: bold;
    text-align: right;
  }

  .logo {
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
    margin: 5px 0;

    img {
      max-width: 169px;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }

  @include device-size('lg') {
    & > div {
      padding-left: 60px;
      padding-right: 60px;
    }

    .logo {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
  }

  @media screen and (min-width: $screen-xl) {
    .logo img {
      margin: 0;
    }
  }
}
